'use client'

import { Menu, MenuButton, MenuItem, MenuList } from '@/components/Menu'
import { ChevronDown, ChevronRight, FeatherIcon, LandmarkIcon, MegaphoneIcon, Scroll } from 'lucide-react'
import { desktop } from '@/styles/theme.css'
import LawIcon from '@/public/assets/law.icon.svg'
import { Row } from '@christiankaindl/lyts'
import Link from 'next/link'
import { Menubar } from '@ariakit/react'
import { pathMappings } from '@/lib/paths'
import { urlify } from '@/lib/utils'

export default function NavBarMenus () {
  const judikaturPaths = Object
    .values(pathMappings.judikatur.paths)
    // @ts-expect-error hidden is only defined on some fields...
    .filter(({ hidden }) => !hidden)
  return (
    <Row gap='1px' className={desktop} asChild>
      <Menubar>
        <Menu>
          <MenuItem render={<MenuButton showOnHover />} style={{ fontWeight: 400 }}>
            Bundesrecht <ChevronDown size={16} style={{ marginRight: -4, marginLeft: -4, marginBottom: -2 }} />
          </MenuItem>
          <MenuList>
            <Link href='/bundesrecht' style={{ fontSize: 'large', padding: '6px 15px', display: 'inline-block' }}>
              <b>Bundesrecht</b>
            </Link>
            <MenuItem icon={LawIcon} iconColor='#987C4D' href='/bundesrecht/bundesverfassungsgesetze'>
              <Row gap={0.5}>
                Verfassungsgesetze
              </Row>
            </MenuItem>
            <MenuItem icon={LawIcon} iconColor='#987C4D' href='/bundesrecht/bundesgesetze'>
              <Row gap={0.5}>
                Bundesgesetze
              </Row>
            </MenuItem>
            <MenuItem icon={Scroll} iconColor='#987C4D' href='/bundesrecht/verordnungen'>
              <Row gap={0.5}>
                Verordnungen
              </Row>
            </MenuItem>
            <MenuItem icon={FeatherIcon} iconColor='#987C4D' href='/bundesrecht/vertraege'>
              <Row gap={0.5}>
                Verträge
              </Row>
            </MenuItem>
            <MenuItem icon={MegaphoneIcon} iconColor='#987C4D' href='/bundesrecht/kundmachungen'>
              <Row gap={0.5}>
                Kundmachungen
              </Row>
            </MenuItem>
            {/* <MenuSeparator /> */}
            <MenuItem href='/bundesrecht'>
              <Row gap={0.5} xAlign='center'>
                Alle anzeigen <ChevronRight size={16} />
              </Row>
            </MenuItem>
          </MenuList>
        </Menu>

        <Menu>
          <MenuItem render={<MenuButton showOnHover />} style={{ fontWeight: 400 }}>
            Judikatur <ChevronDown size={16} style={{ marginRight: -4, marginLeft: -4, marginBottom: -2 }} />
          </MenuItem>
          <MenuList>
            <Link href='/judikatur' style={{ fontSize: 'large', padding: '6px 15px', display: 'inline-block' }}>
              <b>Judikatur</b>
            </Link>
            {judikaturPaths.map(({ name }, index) => {
              if (index >= 5) return null

              return (
                <MenuItem key={name} icon={LandmarkIcon} iconColor='#987C4D' href={`/judikatur/${urlify(name)}`}>
                  <Row gap={0.5}>
                    {name}
                  </Row>
                </MenuItem>
              )
            })}
            <MenuItem href='/judikatur'>
              <Row gap={0.5} xAlign='center'>
                Alle anzeigen <ChevronRight size={16} />
              </Row>
            </MenuItem>
          </MenuList>
        </Menu>

        <Menu>
          <MenuItem render={<MenuButton showOnHover />} style={{ fontWeight: 400 }}>
            Themen <ChevronDown size={16} style={{ marginRight: -4, marginLeft: -4, marginBottom: -2 }} />
          </MenuItem>
          <MenuList>
            <div style={{ fontSize: 'large', padding: '6px 15px', display: 'inline-block' }}>
              <b>Themen</b>
            </div>
            <MenuItem href='/blog'>
              <Row gap={0.5}>
                Blog
              </Row>
            </MenuItem>
            <MenuItem href='/themen/grundlagen-des-rechts'>
              <Row gap={0.5}>
                Grundlagen des Rechts
              </Row>
            </MenuItem>
            <MenuItem iconColor='#987C4D' href='/themen/schule'>
              <Row gap={0.5}>
                Schule
              </Row>
            </MenuItem>
            <MenuItem iconColor='#987C4D' href='/themen/studium'>
              <Row gap={0.5}>
                Studium
              </Row>
            </MenuItem>
            <MenuItem iconColor='#987C4D' href='/themen/strafrecht'>
              <Row gap={0.5}>
                Strafrecht
              </Row>
            </MenuItem>
          </MenuList>
        </Menu>
      </Menubar>
    </Row>
  )
}
