'use client'

import { createMedia } from '@artsy/fresnel'

const MediaQuery = createMedia({
  breakpoints: {
    xs: 0,
    mobile: 712,
  },
})

// Make styles for injection into the header of the page
export const mediaStyles = MediaQuery.createMediaStyle()

export const { Media, MediaContextProvider } = MediaQuery

export function Desktop ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<'div'>) {
  return (
    <Media {...props} greaterThanOrEqual='mobile'>
      {children}
    </Media>
  )
}

export function Mobile ({ children, ...props }: { children: React.ReactNode } & React.HTMLAttributes<'div'>) {
  return (
    <Media {...props} lessThan='mobile'>
      {children}
    </Media>
  )
}
