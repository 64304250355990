'use client'

import { type ElementType, forwardRef } from 'react'
import * as styles from './Menu.css'
import Link from 'next/link'
import { Row } from '@christiankaindl/lyts'
import * as AriakitMenu from '@ariakit/react/menu'

interface MenuItemProps extends AriakitMenu.MenuItemProps {
  icon: ElementType
  iconColor?: string
  [key: string]: any
}
export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(function MenuItem ({ children, icon: Icon, iconColor, ...props }, ref) {
  const btnContent = (
    <>
      {Icon ? <Icon size={20} width={20} style={{ color: iconColor }} /> : null}
      {children}
    </>
  )

  return (
    <AriakitMenu.MenuItem render={props.render ?? <Row className={styles.item} asChild={props.href !== undefined} />} {...props} ref={ref}>
      {props.href === undefined
        ? btnContent
        : (
          <Link href={props.href}>
            {btnContent}
          </Link>
          )
      }
    </AriakitMenu.MenuItem>
  )
})
