'use client'

import { theme } from '@/styles/theme.css'
import { type FunctionComponent } from 'react'
import * as AriakitMenu from '@ariakit/react/menu'

export const MenuSeparator: FunctionComponent = function () {
  return (
    <AriakitMenu.MenuSeparator
      style={{
        marginTop: '6px',
        marginBottom: '6px',
        height: 6,
        opacity: 0.6,
        backgroundColor: theme.colors.backgrounds.bgA3,
        marginLeft: -6,
        marginRight: -6,
      }}
    />
  )
}
