'use client'

import { useIsTop } from './hooks'
import { blurred } from './NavBar.css'

export default function Background () {
  const isTop = useIsTop(1)

  return (
    <div className={blurred} style={{ opacity: isTop ? 0.3 : 1 }} />
  )
}
