'use client'

import { useStore } from '@/lib/store/store'
import { writable } from 'svelte/store'
import Search from '../Search/Search'
import { Suspense } from 'react'
import { desktop } from '@/styles/theme.css'

export const isGhostStore = writable(false)

const style = { width: '23em' }
const inputProps = { style: { paddingRight: 95 } }
export function NavBarSearch () {
  const ghost = useStore(isGhostStore)

  return (
    <Suspense>
      <Search enableShortcut={!ghost} id='navbar-search' style={style} inputProps={inputProps} className={desktop} />
    </Suspense>
  )
}
