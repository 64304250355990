'use client'

import { Row, Stack } from '@christiankaindl/lyts'
import { ComboboxItem, ComboboxPopover } from '@ariakit/react'
import { memo } from 'react'
import SearchProvider, { useSearchResults } from './SearchProvider'
import LawIcon from '@/public/assets/law.icon.svg'
import * as styles from './Search.css'
import { getUrl } from '@/lib/utils'
import { ResultItem } from './Search'
import Button from '../Button/Button'
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip/Tooltip'
import { tooltipInfo } from '@/app/[category]/[subCategory]/[norm]/law.css'
import { theme } from '@/styles/theme.css'
import Text from '../Text/Text'

const SearchPopover = memo(function SearchPopover ({ query }: { query: string }) {
  return (
    <ComboboxPopover flip={false} sameWidth gutter={8} className={styles.suggestionsContainer}>
      <SearchPopoverContent query={query} />
    </ComboboxPopover>
  )
})
export default SearchPopover

export const SearchPopoverContent = memo(function SearchPopoverContent ({ query }: { query: string }) {
  return (
    <>
      <SearchProvider
        sortBy={['_text_match']}
        id='rich-results'
        query={query}
        minCharacters={0}
        params={{
          q: query,
          per_page: 7,
          group_by: undefined,
          facet_by: undefined,
          query_by_weights: '150,1',
          query_by: 'citation,title',
          filter_by: 'documentType:law || documentType:clause',
          exclude_fields: 'content',
          text_match_type: 'max_weight',
          stopwords: undefined,
          include_fields: 'id,abbreviation,lawTitle,lawType,documentType,enumeration,title',
        }}
      >
        {query && (
          <RichResultIndex />
        )}
      </SearchProvider>

      <SearchProvider
        minCharacters={0}
        sortBy={['_text_match', 'count']}
        id='query-suggestions'
        query={query || '*'}
        indexName='query_suggestions'
        params={{
          q: query,
          per_page: 7,
          query_by: 'q',
          facet_by: undefined,
          filter_by: undefined,
          group_by: undefined,
          stopwords: undefined,
        }}
      >
        <QuerySuggestions query={query} />
      </SearchProvider>
    </>
  )
})

function QuerySuggestions ({ query }: { query: string }) {
  const results = useSearchResults()

  return (
    <>
      {results?.hits?.map((hit) => {
        if (hit === undefined) return null
        return (
          // @ts-expect-error need customizable useSearch() types
          <ComboboxItem hideOnClick render={<ResultItem hit={hit.document} />} key={hit.document.id} value={hit.document.q} />
        )
      })}
      {results?.hits?.length === 0 && query && (
        <ComboboxItem hideOnClick render={<ResultItem hit={{ q: `Suche nach ${query}` }} query={query} />} value={query} />
      )}
    </>
  )
}

function RichResultIndex () {
  const results = useSearchResults()

  if (!results?.hits?.[0]?.document.abbreviation) {
    return null
  }

  return (
    <Row
      gap={0.5}
      style={{
        overflow: 'auto',
        scrollbarWidth: 'none',
        marginLeft: -6,
        marginRight: -6,
        padding: '6px 12px 3px',
      }}
    >
      {results?.hits.map((hit) => {
        return <RichResult key={hit.document.id} hit={hit.document} />
      })}
    </Row>
  )
}

type RichResultProps = React.HTMLProps<HTMLDivElement> & {
  hit: any
  onSelect?: () => void
  index?: number
}
export const RichResult = function RichResult ({ hit }: RichResultProps) {
  return (

    <Tooltip placement='bottom-start'>
      <TooltipTrigger>
        <Button style={{ color: '#A48757', backgroundColor: 'rgba(228, 177, 95, 0.2)' }} size='tiny' circular href={getUrl(hit.lawType, { norm: hit.abbreviation ?? hit.normId, abbrNr: hit.abbrNr, enumeration: hit.documentType === 'clause' ? hit.enumeration : undefined })}>
          {hit.documentType === 'law' && <LawIcon width={16} />}
          {hit.documentType === 'clause' && hit.enumeration && <>{hit.enumeration}&ensp;</>}
          {hit.abbreviation}
        </Button>
      </TooltipTrigger>
      <TooltipContent style={{ textAlign: 'left' }} gutter={3}>
        <Stack gap={0.25}>
          {hit.documentType === 'clause' && (
            <Text weight={600} size='small'>
              {hit.enumeration}&ensp;{hit.title}
            </Text>
          )}
          <Row tabIndex={0} gap={0.25} className={tooltipInfo} style={{ fontWeight: 600, color: theme.colors.text.brand }}>
            <LawIcon width={16} />
            <Text color='inherit' size='small'>{hit.lawTitle}</Text>
          </Row>
        </Stack>
      </TooltipContent>
    </Tooltip>
  )
}
