export const defaultParams = {
  facet_by: 'documentType,institution,lawType,legalCategories',
  per_page: 40,
  exclude_fields: 'embedding,refs,fullContent',
  group_by: 'normId',
  group_limit: 4,
  use_cache: true,
  highlight_affix_num_tokens: 25,
  highlight_fields: 'content,title',
  stopwords: 'common-words',
  enable_typos_for_numerical_tokens: false,
  enable_overrides: true,
}
